
    $('.manualSlideBox').slick({
    dots: false,
    variableWidth: true,
    arrows: false,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
});
